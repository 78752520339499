function formatPhoneNumber(phoneNumber) {
  // Ensure the phone number is a string
  phoneNumber = phoneNumber.toString();

  // Extract the parts of the phone number
  const countryCode = '+7';
  const areaCode = phoneNumber.substring(0, 3);
  const firstPart = phoneNumber.substring(3, 6);
  const secondPart = phoneNumber.substring(6, 8);
  const thirdPart = phoneNumber.substring(8);

  // Combine them into the desired format
  const formattedNumber = `${countryCode} (${areaCode}) ${firstPart} ${secondPart} ${thirdPart}`;
  return formattedNumber;
}

export { formatPhoneNumber };
